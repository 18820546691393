const pcHome = () => import('@/views/pc/home/index.vue'); // 首页+
const pcMeiti = () => import('@/views/pc/meiti/index.vue'); // 媒体与合作
const pcAnti = () => import('@/views/pc/anti/index.vue'); // 防违
const pcStory = () => import('@/views/pc/story/index.vue'); // 花寇故事
const pcProduct = () => import('@/views/pc/product/index.vue'); // 花寇故事
export default [
  {
    path: '/pc/home',
    component: pcHome,
    name: 'pcHome'
  },
  {
    path: '/pc/media',
    component: pcMeiti,
    name: 'pcMeiti'
  },
  {
    path: '/pc/anti',
    component: pcAnti,
    name: 'pcAnti'
  },
  {
    path: '/pc/story',
    component: pcStory,
    name: 'pcStory'
  },
  {
    path: '/pc/product',
    component: pcProduct,
    name: 'pcProduct'
  }
];
