import Vue from 'vue';
import VueRouter from 'vue-router';
import Entry from '@/views/entry';
import pcRoutes from './pc';
import mobileRoutes from './mobile';

Vue.use(VueRouter);

// 解决重复点击路由报错的BUG
// 下面这段代码主要解决这个问题 ：Uncaught (in promise) Error: Redirected when going from "/login" to "/index" via a navigation guard.
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    name: 'Entry',
    component: Entry
  },
  ...pcRoutes,
  ...mobileRoutes
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.VUE_APP_URL,
  routes
});

const userAgent = window.navigator.userAgent;
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

router.beforeEach((to, from, next) => {
  const isMobilePath = to.path.startsWith('/mobile/');
  const isPCPath = to.path.startsWith('/pc/');
  // if (isMobile) {
  //   // 如果是移动设备，重定向 PC 路径到移动路径
  //   if (isPCPath) {
  //     const path = to.path.match(/^\/pc\/(.*)$/)[1];
  //     return next({ path: `/mobile/${path}`, query: to.query }); // 保留查询参数
      
  //   }
  // } else {
  //   // 如果是桌面设备，重定向移动路径到 PC 路径
  //   if (isMobilePath) {
  //     const path = to.path.match(/^\/mobile\/(.*)$/)[1];
  //     return next({ path: `/pc/${path}`, query: to.query }); // 保留查询参数
     
  //   }
  // }
  // 每次进入页面滚动到顶部
  window.scrollTo({ top: 0, behavior: 'smooth' });
  next();
});
export default router;
